import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Les randonnées" />
      <section className="landing-section"></section>
      <section className="blog-list">
        <h2 className="blog-list-title">Dernières randonnées :</h2>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header>
                <Link to={node.fields.slug}>
                  <h3>
                    <span className="underline">{title}</span>
                  </h3>
                  <div className="subtitle">
                    <span>
                      <span role="img" aria-label="horloge">
                        🕔
                      </span>{" "}
                      {node.frontmatter.duration}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span>
                      <span role="img" aria-label="carte">
                        🗺️
                      </span>{" "}
                      {node.frontmatter.distance}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span>
                      <span role="img" aria-label="position">
                        📍
                      </span>{" "}
                      {node.frontmatter.department}
                    </span>
                  </div>
                </Link>
              </header>
              {/* <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section> */}
            </article>
          )
        })}
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM")
            title
            description
            duration
            distance
            department
          }
        }
      }
    }
  }
`
